
import SegmentCategoriesHeader from "./SegmentCategoriesHeader.vue";
import Vue from "vue";

export default Vue.extend({
    name: "SegmentCategory",
    props: {},
    components: { SegmentCategoriesHeader },
    data: () => ({}),
    created() {},
    async mounted() {},
    computed: {},
    methods: {},
});	
